<template>
  <div>
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>模考</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '' }">考试详情</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="button-wrap">
      </div>
    </div>
    <el-card style="margin-top:20px;">
      <TableTittle :tittle="TableName[0]" />
      <el-descriptions :column="3"
                       border
                       :labelStyle="labelStyle"
                       :contentStyle="contentStyle">
        <el-descriptions-item>
          <template slot="label">
            学员信息
          </template>
          {{dataForm.student?`${dataForm.student.realname}/${dataForm.student.phone}`:""}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            报名班次
          </template>
          {{dataForm.student_product_obj?dataForm.student_product_obj.product_names:""}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            试卷名称
          </template>
          {{dataForm.exam_paper_obj?dataForm.exam_paper_obj.name:""}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            考试状态
          </template>
          <el-tag type="danger"
                  size="mini"
                  v-if="dataForm.exam_status==0">未开始</el-tag>
          <el-tag size="mini"
                  v-if="dataForm.exam_status==1">已开考</el-tag>
          <el-tag type="success"
                  size="mini"
                  v-if="dataForm.exam_status==2">已交卷</el-tag>
          <el-tag type="danger"
                  size="mini"
                  v-if="dataForm.exam_status==3">超时卷</el-tag>
          <el-tag type="danger"
                  size="mini"
                  v-if="dataForm.exam_status==4">未交卷</el-tag>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            批改状态
          </template>
          <el-tag type="info"
                  size="mini"
                  v-if="dataForm.correct_status==0">未批改</el-tag>
          <el-tag type="info"
                  size="mini"
                  v-if="dataForm.correct_status==1">待审核</el-tag>
          <el-tag type="info"
                  size="mini"
                  v-if="dataForm.correct_status==2">已批改</el-tag>
          <el-tag type="info"
                  size="mini"
                  v-if="dataForm.correct_status==3">已拒绝</el-tag>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            考试成绩
          </template>
          <div class="descriptions-item-content">
            <div style="display:flex;align-items:center;width:230px;">
              <span v-if="!editMode"> {{dataForm.score}}</span>
              <el-input-number v-model="dataForm.score"
                               v-else
                               style="width:130px;"
                               size="mini"
                               @blur="changeScore"
                               :max="300"></el-input-number>
              &nbsp;分
            </div>

            <el-button type="primary"
                       size="mini"
                       @click="editMode=!editMode"
                       v-if="!editMode&&dataForm.correct_status==2">修改</el-button>
          </div>
        </el-descriptions-item>
      </el-descriptions>
    </el-card>
    <div class="wrap">
      <el-card class="container">
        <div class="header">
          <TableTittle :tittle="TableName[1]" />
        </div>
        <template v-if="iframeUrl.length">
          <div class="pre-content"
               v-for="(item,index) in iframeUrl"
               :key="index">
            <img :src="item.url"
                 alt=""
                 srcset=""
                 v-if="item.url.indexOf('.png') != -1||item.url.indexOf('.jpeg') != -1||item.url.indexOf('.jpg') != -1"
                 class="img-content">
            <template v-else>
              <iframe :src="item.url"
                      frameborder="0"
                      class="frame-content"
                      v-if="item.url.indexOf('.pdf') != -1">
              </iframe>
              <div class="btn frame-content"
                   @click="look(item)"
                   v-else>暂不支持word在线预览点击请下载</div>
              <!-- <iframe :src="baseurl+item.url"
                      frameborder="0"
                      class="frame-content"
                      v-else> -->
              <!-- </iframe> -->
            </template>
          </div>
        </template>
        <div class="empty-container"
             v-else> <img src="@/assets/images/empty.png"
               alt=""
               srcset="">
          <div class="text">学生还未提交试卷哦～</div>
        </div>
      </el-card>
      <el-card class="container">
        <div class="header">
          <TableTittle :tittle="TableName[2]" />

          <div style="display:flex;">

            <div v-if="[1].includes(dataForm.correct_status)&&[2,3].includes(dataForm.exam_status)">
              <el-button type="primary"
                         size="small"
                         @click="changeCorrectStatus(2)">通 过</el-button>
              <el-button type="danger"
                         size="small"
                         plain
                         @click="changeCorrectStatus(3)">驳 回</el-button>
            </div>
            <div v-else-if="dataForm.correct_status==2&&[2].includes(dataForm.exam_status)">
              <el-button type="info"
                         size="small"
                         disabled>已批改</el-button>
            </div>
            <div v-else-if="dataForm.correct_status==3&&[2].includes(dataForm.exam_status)">
              <el-button type="info"
                         size="small"
                         disabled>已拒绝</el-button>
            </div>
            <el-button type="primary"
                       size="small"
                       v-if="iframeUrlRight.length"
                       @click="openuploadDialog"
                       style="margin-left:16px;">提交批改</el-button>
          </div>

        </div>
        <template v-if="iframeUrlRight.length">
          <div class="pre-content"
               v-for="(item,index) in iframeUrlRight"
               :key="index">
            <img :src="item.url"
                 alt=""
                 srcset=""
                 v-if="item.url.indexOf('.png') != -1||item.url.indexOf('.jpeg') != -1||item.url.indexOf('.jpg') != -1"
                 class="img-content">
            <template v-else>
              <iframe :src="item.url"
                      frameborder="0"
                      class="frame-content"
                      v-if="item.url.indexOf('.pdf') != -1"></iframe>
              <div class="btn frame-content"
                   @click="look(item)"
                   v-else>暂不支持word在线预览点击请下载</div>
              <!-- <iframe :src="baseurl+item.url"
                      frameborder="0"
                      class="frame-content"
                      v-else>
              </iframe> -->
            </template>
          </div>
        </template>
        <div class="empty-container"
             v-else>
          <img src="@/assets/images/empty.png"
               alt=""
               srcset="">
          <div class="text">学长还未进行批改哦～</div>
          <el-button type="primary"
                     size="small"
                     style="margin-top:16px;"
                     @click="openuploadDialog">提交批改</el-button>
        </div>
      </el-card>
    </div>
    <RefuseDialog :isVisible="refuseDialog"
                  @close="refuseDialog=false"
                  @submit="RefuseDialogSubmit" />
    <UploadCorrect :isVisible="uploadDialog"
                   @close="uploadDialog=false"
                   @submit="UploadCorrectSubmit"
                   :content="editContent" />
  </div>
</template>

<script>
import TableTittle from '../../../components/TableTittle/TableTittle.vue'
import RefuseDialog from '@/components/DialogComponents/RefuseDialog.vue'
import UploadCorrect from '@/components/DialogComponents/PracticeTest/UploadCorrect'
import { mockexamprocessOne, mockexamprocessEdit } from '@/request/api'
export default {
  components: {
    TableTittle,
    RefuseDialog,
    UploadCorrect,
  },
  data() {
    return {
      TableName: ['考试信息', '答题详情', '批改详情'],
      labelStyle: {
        height: '52px',
        width: '152px',
      },
      contentStyle: {},
      dataForm: {},
      iframeUrl: '',
      iframeUrlRight: '',
      refuseDialog: false,
      baseurl: 'https://docs.google.com/gview?embedded=true&url=',
      uploadDialog: false,
      editContent: null,
      editMode: false,
    }
  },
  filters: {},
  watch: {},
  created() {
    this.fetchDetail()
  },
  mounted() {},
  methods: {
    look(item) {
      window.open(item.url)
    },
    fetchDetail() {
      mockexamprocessOne({ id: this.$route.params.id }).then((res) => {
        this.dataForm = res.data
        if (res.data.content_answer) {
          this.iframeUrl = JSON.parse(res.data.content_answer)
        }
        if (res.data.content_correct) {
          this.iframeUrlRight = JSON.parse(res.data.content_correct)
        }
      })
    },
    RefuseDialogSubmit({ text }) {
      this.handleToEdit(3, text)
      this.refuseDialog = false
    },
    handleToEdit(status, refuse_reason = '') {
      let query = {
        id: this.dataForm.id,
        correct_status: status,
        refuse_reason: refuse_reason,
        read_status: 1,
      }
      mockexamprocessEdit(query).then((res) => {
        this.fetchDetail()
      })
    },
    changeCorrectStatus(status) {
      switch (status) {
        case 2:
          this.$confirm('是否通过此试卷', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }).then(() => {
            this.handleToEdit(2)
          })
          break
        case 3:
          this.refuseDialog = true
          break
        default:
          break
      }
    },
    UploadCorrectSubmit() {
      this.fetchDetail()
    },
    openuploadDialog() {
      this.editContent = Object.assign({}, this.dataForm)
      this.uploadDialog = true
    },
    changeScore() {
      let query = {
        id: this.dataForm.id,
        score: this.dataForm.score,
      }
      mockexamprocessEdit(query).then((res) => {
        this.editMode = false
        this.fetchDetail()
      })
    },
  },
}
</script>

<style lang="less" scoped>
.btn {
  cursor: pointer;
  border-radius: 16px;
  text-align: center;
  line-height: 80px;
  color: #409eff;
  width: 180px;
  height: 80px;
  text-decoration: underline;
  // border-bottom: 1px solid #409eff;
}
.descriptions-item-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.wrap {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  .container {
    width: 49.5%;
    display: flex;
    flex-direction: column;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }
    .pre-content {
      overflow: scroll;
      max-height: 800px;

      .img-content {
        width: 100%;
        margin-bottom: 16px;
      }
    }
    .frame-content {
      width: 100%;
      flex: 1;
      height: 729px;
    }
  }
}
.empty-container {
  width: 100%;
  height: 500px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  img {
    width: 120px;
    height: 120px;
    margin-bottom: 16px;
  }
  .text {
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #909399;
  }
}
</style>