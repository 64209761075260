<template>
  <div>
    <el-dialog title="资料编辑"
               :visible.sync="dialogFormVisible"
               width="620px"
               @close="emitClose">
      <el-form :model="dataForm"
               label-position="right"
               ref="formRules"
               :rules="rules"
               label-width="100px"
               size="small">
        <el-form-item label="名称："
                      prop="name">
          <el-input v-model="dataForm.name"
                    placeholder="请输入名称"></el-input>
        </el-form-item>
        <el-form-item label="年份：">
          <el-select v-model="dataForm.year"
                     placeholder="请选择年份">
            <el-option label="不限"
                       value=""></el-option>
            <el-option label="2022年"
                       value="2022"></el-option>
            <el-option label="2023年"
                       value="2023"></el-option>
            <el-option label="2024年"
                       value="2024"></el-option>
            <el-option label="2025年"
                       value="2025"></el-option>
            <el-option label="2026年"
                       value="2026"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="规格："
                      prop="sku">
          <el-input v-model="dataForm.sku"
                    placeholder="请输入规格"></el-input>
        </el-form-item>
        <el-form-item label="简称："
                      prop="short_name">
          <el-input v-model="dataForm.short_name"
                    placeholder="请输入简称"></el-input>
        </el-form-item>
        <el-form-item label="标签：">
          <el-tag :key="tag"
                  v-for="tag in tagData"
                  closable
                  :disable-transitions="false"
                  @close="handleClose(tag)">
            {{tag}}
          </el-tag>
          <el-input class="input-new-tag"
                    v-if="tagvalueVisible"
                    v-model="tagValue"
                    ref="saveTagInput"
                    size="small"
                    @keyup.enter.native="handleInputConfirm"
                    @blur="handleInputConfirm"></el-input>
          <el-button v-else
                     class="button-new-tag"
                     size="small"
                     @click="showInput">添加标签</el-button>
        </el-form-item>
        <el-form-item label="上传图片："
                      prop="pictures"
                      ref="image">
          <el-upload :action="imgUploadUrl"
                     list-type="picture-card"
                     :on-remove="handleRemove"
                     :on-success="handleSuccess"
                     :file-list="fileList"
                     :limit="1">
            <i class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="适用范围："
                      prop="colleges_majors">
          <div class="choose-tag-wrap">
            <div class="link-wrap">
              院校选择
              <el-link type="primary"
                       :underline="false"
                       @click="collegeChooseDialog=true">选择院校</el-link>
            </div>
            <div class="tags-wrap">
              <el-tag :key="index"
                      v-for="(item,index) in colleges"
                      closable
                      :disable-transitions="false"
                      @close="handleCollegeRemove(item)">
                {{item.name}}
              </el-tag>
            </div>
          </div>
          <div class="choose-tag-wrap">
            <div class="link-wrap">
              专业选择
              <el-link type="primary"
                       :underline="false"
                       @click="majorChooseDialog=true">选择专业</el-link>
            </div>
            <div class="tags-wrap">
              <el-tag :key="index"
                      v-for="(item,index) in majors"
                      closable
                      :disable-transitions="false"
                      @close="handleMajorRemove(item)">
                ({{item.code}}){{item.name}}
              </el-tag>
            </div>
          </div>
        </el-form-item>
        <!--       <el-form-item
          label="适合科目："
          prop="subjects"
        >
          <div class="tag-wrap">
            <el-tag
              class="subject-item"
              size="medium"
              :key="item.id"
              v-for="item in subjects"
              closable
              :disable-transitions="false"
              @close="handleSubjectRemove(item)"
            >
              {{
                  item.college_name
                    ? `${item.college_name}-${item.name}`
                    : item.name
                }}
            </el-tag>
            <el-button
              type="text"
              @click="handleSubjectChoose"
            >选择科目</el-button>
          </div>
        </el-form-item> -->
        <el-form-item label="备注：">
          <el-input v-model="dataForm.remark"
                    type="textarea"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer"
           class="dialog-footer">
        <el-button @click="emitClose">取 消</el-button>
        <el-button type="primary"
                   @click="submit('formRules')">确 定</el-button>
      </div>

    </el-dialog>
    <SubjectChoose :visible="subjectChooseDialog"
                   :subjects="subjects"
                   @close="subjectChooseDialog = false"
                   @submit="saveSubject"></SubjectChoose>
    <CollegeChoose :visible="collegeChooseDialog"
                   :colleges="colleges"
                   @close="collegeChooseDialog = false"
                   @submit="saveCollege"></CollegeChoose>
    <MajorChoose :visible="majorChooseDialog"
                 :majors="majors"
                 @close="majorChooseDialog = false"
                 @submit="saveMajor"></MajorChoose>
  </div>
</template>

<script>
import SubjectChoose from '@/components/DialogComponents/SubjectChoose.vue'
import CollegeChoose from '@/components/DialogComponents/CollegeChoose.vue'
import MajorChoose from '@/components/DialogComponents/MajorChoose.vue'
import { imgUploadUrl, fileUploadUrl } from '@/request/http'
import { materialEdit, materialOne } from '@/request/api'

export default {
  components: {
    SubjectChoose,
    CollegeChoose,
    MajorChoose,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: () => {
        return null
      },
    },
  },
  data() {
    return {
      dataForm: {},
      dialogFormVisible: this.isVisible,
      rules: {
        name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
        // year: [{ required: true, message: "请选择年份", trigger: "blur" }],
        sku: [{ required: true, message: '请输入规格', trigger: 'blur' }],
        short_name: [
          { required: true, message: '请输入简称', trigger: 'blur' },
        ],
        pictures: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (!this.valiIconFlag) {
                callback(new Error('请上传图片'))
              } else {
                callback()
              }
            },
          },
        ],
        /*       colleges_majors: [{
                required: true,
                validator: (rule, value, callback) => {
                  if (!this.colleges.length && !this.majors.length) {
                    callback(new Error('请选择适用范围'))
                  } else {
                    callback()
                  }
                },
              }], */
      },
      subjectChooseDialog: false,
      subjects: [],
      tagData: [],
      tagvalueVisible: false,
      tagValue: '',
      imgUploadUrl: imgUploadUrl(),
      fileList: [],
      collegeChooseDialog: false,
      colleges: [],
      majorChooseDialog: false,
      majors: [],
      valiIconFlag: true,
    }
  },
  mounted() {},
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val
      if (val) {
        this.fetchDetail(this.id)
      }
    },
  },
  methods: {
    fetchDetail(id) {
      materialOne({ id }).then((res) => {
        this.dataForm = res.data
        this.tagData = this.dataForm.tags ? this.dataForm.tags.split(',') : []
        this.fileList = this.dataForm.pictures.map((v) => {
          return {
            url: v.picture_url,
          }
        })
        this.colleges = this.dataForm.colleges
        this.majors = this.dataForm.majors
      })
    },
    saveSubject({ subjects }) {
      this.subjects = subjects
    },
    emitClose() {
      this.$refs['formRules'].resetFields()
      this.fileList = []
      this.tagData = []
      this.colleges = []
      this.majors = []
      this.$emit('close')
    },
    submit(rules) {
      this.$refs[rules].validate((valid) => {
        if (valid) {
          const params = Object.assign({}, this.dataForm, {
            cid: localStorage.getItem('cid'),
            tags: this.tagData.join(',') || '',
            pictures: this.fileList.map((v) => {
              return { picture_url: v.url }
            }),
            majors: this.majors.map((v) => {
              return { code: v.code }
            }),
            colleges: this.colleges.map((v) => {
              return { code: v.code }
            }),
            picture_url: this.fileList[0].url,
          })
          delete params.watcher
          delete params.creator
          materialEdit(params).then((res) => {
            if (res.code == 0) {
              this.$message.success(`保存成功`)
              this.emitClose()
              this.$emit('submit')
            }
          })
          console.log(params)
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    handleSubjectChoose() {
      this.subjectChooseDialog = true
    },
    handleSubjectRemove(item) {
      this.subjects = this.subjects.filter((v) => v.id !== item.id)
    },
    handleClose(tag) {
      this.tagData.splice(this.tagData.indexOf(tag), 1)
    },

    showInput() {
      this.tagvalueVisible = true
      this.$nextTick((_) => {
        this.$refs.saveTagInput.$refs.input.focus()
      })
    },

    handleInputConfirm() {
      let inputValue = this.tagValue
      if (inputValue) {
        this.tagData.push(inputValue)
      }
      this.tagvalueVisible = false
      this.tagValue = ''
    },
    handleRemove(file, fileList) {
      this.fileList = fileList
      if (this.fileList.length == 0) {
        this.valiIconFlag = false
        this.$refs.image.validate()
      }
    },
    handleSuccess(response, file, fileList) {
      this.fileList.push({
        url: response.data.url,
      })
      this.valiIconFlag = true
      this.$refs.image.clearValidate()
      this.$message.success('上传成功')
    },
    saveCollege({ colleges }) {
      this.colleges = colleges
    },
    saveMajor({ majors }) {
      this.majors = majors
    },
    handleCollegeRemove(item) {
      this.colleges = this.colleges.filter((v) => v.id !== item.id)
    },
    handleMajorRemove(item) {
      this.majors = this.majors.filter((v) => v.id !== item.id)
    },
  },
}
</script>

<style lang="less" scoped>
.tag-wrap {
  display: flex;
  flex-wrap: wrap;
  .subject-item {
    margin-right: 5px;
    margin-bottom: 5px;
  }
}
.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
/deep/.el-upload {
  width: 96px;
  height: 96px;
  line-height: 96px;
}
/deep/.el-upload-list__item {
  width: 96px !important;
  height: 96px !important;
}
.choose-tag-wrap {
  .link-wrap {
    display: flex;
    align-items: center;
    .el-link {
      margin-left: 16px;
    }
  }
  .tags-wrap {
    .el-tag {
      margin: 4px;
    }
  }
}
</style>